<!--
 * @Author: your name
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2024-10-15 19:18:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\signIn\signInList.vue
-->
<template>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <div class="signinlist_page">
      <div v-for="(item, index) in dataList" :key="index" class="page_body">
        <div class="body_title">
          {{ item.yyztmc }}
        </div>
        <!-- <div class="body_type">{{ item.queueStatus=='1'?'候诊':'签到' }}</div> -->
        <div class="body_item">
          <div class="item_left">
            预约序号
          </div>
          <div class="item_right">
            <div class="item_name">
              {{ item.serialNum }}
            </div>
          </div>
        </div>
        <div class="body_item">
          <div class="item_left">
            等待人数
          </div>
          <div class="item_right">
            <div class="item_num">
              {{ item.frontNum }} 人
            </div>
          </div>
        </div>
        <div class="body_item">
          <div class="item_left">
            就诊人
          </div>
          <div class="item_right">
            <div class="item_name">
              {{ item.patName }}
            </div>
          </div>
        </div>
        <div class="body_item">
          <div class="item_left">
            医生姓名
          </div>
          <div class="item_right">
            <div class="item_name">
              {{ item.docName }}
            </div>
          </div>
        </div>
        <div class="body_item">
          <div class="item_left">
            科室名称
          </div>
          <div class="item_right">
            <div class="item_name">
              {{ item.deptName }}
            </div>
          </div>
        </div>
        <div class="body_item">
          <div class="item_left">
            就诊位置
          </div>
          <div class="item_right">
            <div class="item_name">
              {{ item.deptLocation }}
            </div>
          </div>
        </div>
        <!-- <div class="body_item">
                    <div class="item_left">门诊类型</div>
                    <div class="item_right">
                        <div class="item_name">{{ item.hylx }} </div>
                    </div>
                </div> -->
      </div>
    </div>
  </van-pull-refresh>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'

export default {
    name:'SignInList',
    data (){
        return{
            id:'',
            dataList:[],
            isLoading:false
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.getSignInQueueListData()
    },
    methods:{
        //获取签到排队列表
        getSignInQueueListData(){
            gjcModel.getSignInQueueListData({id:this.id}).then(res=>{
                console.log('获取签到排队列表', res)
                this.dataList = res.data
                this.isLoading = false
            })
        },
        onRefresh(){
            this.dataList = []
            this.getSignInQueueListData()
        }
    }
}
</script>
<style scoped lang='less'>
    .signinlist_page{
        background-color: #fff;
        width: 100%;
        height: 100%;
    }
    .page_body{
        margin: 20px 24px;
        background-color: #f5f5f5;
        border-radius: 10px;
        .body_title{
            padding: 10px 24px;
            font-size: 32px;
            color: #1e80ff;
            font-weight: bold;
        }
        .body_item{
            display: flex;
            justify-content: space-between;
            justify-items: center;
            padding: 15px 24px;
            .item_left{
                font-size: 30px;
                color: #666;;
                line-height: 40px;
            }
            .item_right{
                margin-left: 30px;
                text-align: right;
                font-size: 32px;
                color: #333;
                line-height: 40px;
                .item_des{
                    font-size: 32px;
                }
                .item_num{
                    font-size: 40px;
                    color: #f80404;
                }
            }
        }
    }
    .page-box{
        padding: 0 30px;
    }
</style>
